.cmp-block {
  &__content {
    @media screen and (max-width: size(medium)) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    @media screen and (max-width: size(small)) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
