.cmp-text {
  margin: 0.75rem 0 0 0;
  color: inherit;
  font-family: defaultFont, serif;
  font-weight: normal;
  font-size: 1em;
  padding-left: 0 !important;
  padding-right: 0 !important;

  p {
    font-family: defaultFont, serif;
  }

  & b {
    font-weight: bold;
  }
  & p a {
    font-family: defaultFont, serif;
    color: $color-accent;
    text-decoration: none;
    border: 0.125em solid transparent;
    border-radius: 0.25em;
    &:hover {
      color: $color-accent-darker;
      text-decoration: underline;
      text-decoration-thickness: 0.125em;
      text-decoration-color: $color-accent-darker;
      text-decoration-style: solid;
    }
    &:focus {
      text-decoration: underline;
      border-color: $color-accent;
      outline: none;
      text-decoration-thickness: 0.125em;
      text-decoration-color: $color-accent;
      text-decoration-style: solid;
    }
  }
  & blockquote {
    margin: 0.75rem 0;
    font-size: 1.75em;
    font-family: titleFont, serif;
    font-style: italic;
    line-height: 2.375rem;
    margin-inline: 0;
  }
  & ul li {
    font-family: defaultFont, serif;
    font-weight: 100;

    &::marker {
      content: "-  ";
    }
  }
  & ol li {
    font-family: defaultFont, serif;
    font-weight: 100;
  }
}
.cmp-text__paragraph {
}
.text {
  font-family: defaultFont, serif;
}
