//== XF Header style, used on page template
#main-header {
  height: 98px;
  margin: auto;
  position: relative;
  display: flex;

  @media screen and (min-width: size(large)) {
    height: 130px;
  }

  //=================== Mobile menu ===================
  .icon-close {
    display: none;
  }

  input {
    width: 25px;
    height: 25px;
    z-index: 10;
    border: none;
    position: absolute;
    top: 32px;
    right: 22px;
  }

  .icon-open,
  .icon-close {
    position: absolute;
    top: 32px;
    right: 45px;
  }

  input:checked ~ .icon-open {
    display: none;
  }

  input:checked ~ .icon-close,
  input:checked ~ .container {
    display: block;
  }

  .icon-open,
  .icon-close {
    &:after {
      width: 32px;
      height: 32px;
      position: absolute;
    }
  }

  .icon-open::after {
    content: '';
    background: url("./resources/images/menu.svg") no-repeat;
  }

  .icon-close::after {
    content: '';
    background: url("./resources/images/close.svg") no-repeat;
  }

  //=================== Grid layout ===================

  & .aem-Grid {
    display: flex;
    align-items: center;

    @media screen and (max-width: size(large)) {
      height: inherit;
    }
  }

  //=================== Logo ===================
  .cmp-image,
  .image {
    width: 52px;
    height: 47px;
    margin: 0;

    @media screen and (min-width: size(large)) {
      width: 94px;
      height: auto;
    }
  }

  .image {
    padding-left: 16px;

    @media screen and (min-width: size(large)) {
      padding-left: 0;
    }
  }

  //=================== Navigation ===================
  .cmp-navigation {
    @media screen and (min-width: size(large)) {
      height: 98px;
    }
  }

  .container {
    display: none;

    @media screen and (max-width: size(large)) {
      width: 100vw;
      position: absolute;
      top: 98px;
      background: white;
      left: 0;
      z-index: 100;

      .aem-Grid {
        flex-direction: column;
      }
    }

    @media screen and (min-width: size(large)) {
      display: block;
    }
  }
  .navigation {
    @media screen and (min-width: size(large)) {
      margin: 0 auto;
      width: auto;
    }
  }

  .cmp-navigation__group {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    list-style: none;
    padding-bottom: 20px;

    @media screen and (min-width: size(large)) {
      align-items: center;
      flex-direction: row;
      padding-bottom: 0;
    }
  }

  .cmp-navigation__item-link {
    @include rem(font-size, 17);
    font-weight: 500;
    line-height: 170%;
    display: block;
    text-decoration: none;
    color: $color-black;
    margin: 0 $margin-mobile;

    &:hover {
      text-decoration: underline;
    }
  }

  .cmp-navigation__item--active > .cmp-navigation__item-link {
    font-family: titleFont, serif;
  }

  //=================== Language Navigation ===================
  .languagenavigation {
    // Mobile Language navigation
    @media screen and (max-width: size(large)) {
      width: fit-content;

      .cmp-language-navigation__dropdown {
        width: 100vw;
        height: 70px;
        background-color: $color-tint-2;
        padding-top: 10px;
        padding-left: 16px;
      }

      .cmp-dropdown {
        padding: 13px 24px;
        border: 1px solid $color-black;
        border-radius: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: fit-content;

        .cmp-dropdown__title {
          font-family: defaultFont, serif;
        }
      }

      .cmp-dropdown__body {
        left: 17px;
        bottom: 69px;
        width: fit-content;

        &:after {
          content: none;
        }
      }
    }

    @media screen and (min-width: size(large)) {
      width: auto;
    }
  }

  //== XF Content Fix

  .xf-content-height {
    min-height: auto;
    margin: 0;
  }
}

#main-header > div {
  @media screen and (min-width: size(small)) {
    padding: 0 25px;
  }

  @media screen and (min-width: size(huge)) {
    padding: 0 $margin;
  }

  @media (min-width: size(gigantic) + 1px) {
    max-width: $grid-huge + $margin*2;
    margin: auto;
    padding: 0;
  }
}
.header--fixed {
  //sticky header
  width: 100%;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: $color-white;
}