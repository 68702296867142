.cmp-button,
.btn--white,
.btn--black {
  padding: 12px 24px;
  font-size: 17px;
  background-color: $color-primary-3;
  border-color: $color-primary-3;
  font-family: defaultFont, serif;
  font-weight: 800 !important;
  color: $color-white;
  &:hover {
    background-color: $color-shade-2;
    border-color: $color-shade-2;
  }
}

.btn--secondary {
  background-color: $color-white;
  color: $color-primary-2 !important;
  border-color: $color-primary-2;
  font-weight: 800 !important;
}

.cmp-link,
.link {
  color: $color-primary-2 !important;
  font-family: defaultFont, serif;
  font-weight: 800 !important;
}
