.cmp-title {
  font-family: titleFont, serif;
  margin-bottom: 2rem !important;
  font-weight: normal;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.cmp-title__text {
  margin: 0;
}

.cmp-title__link {
  color: $color-accent;
  text-decoration: none;
  border: 0.125rem solid transparent;
  border-radius: 0.25rem;

  &:hover {
    color: $color-accent-darker;
    text-decoration: underline;
    text-decoration-color: $color-accent-darker;
    text-decoration-thickness: 0.125rem;
  }

  &:focus {
    color: $color-accent;
    text-decoration: underline;
    border-color: $color-accent-lighter;
    outline: none;
    text-decoration-color: $color-accent-lighter;
    text-decoration-thickness: 0.125rem;
  }
}