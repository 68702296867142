.mediatext {
    .cmp-inline-buttons {
        margin: 0 !important;
    }

    .cmp-text {
        font-weight: 100 !important;
        margin-bottom: 24px;
    }

    .button {
        margin-top: 0.75rem;
    }
}