.block.content .cmp-block__bg-image {
  width: 100%;
  object-fit: cover;
}

.partnerblock {
  margin: 0;

  .multicolumn .cmp-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.partner--gradient {
  background: linear-gradient(159.44deg, #29C196 12.35%, #C0ED48 97.42%)
}