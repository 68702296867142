.cmp-navigation-block {
  &__title-wrapper {
    & .cmp-title--large {
      margin-bottom: 40px !important;
      font-weight: 200;
    }

    & .cmp-title--medium {
      margin-bottom: 40px !important;
      font-weight: 200;
      color: $color-black;
    }
  }

  & .cmp-navigation-side-bar {
    &__nav-wrapper,
    &__nav-wrapper--level-2 {
      & .cmp-navigation-side-bar__nav-item {
        padding: 8px;
        font-weight: 100;

        &--active {
          background: $color-primary-1;
          border: 1px solid $color-primary-1;
        }
      }
    }

    &__nav-wrapper--level-2 .cmp-navigation-side-bar__nav-item {
      padding-left: 32px;
    }
  }

  & .mediatext {
    & .cmp-media-text__content {
      padding: 23px;
    }
  }

  &__content {
    & .cmp-container {
      @media screen and (max-width: size(medium)) {
        margin-left: 1pc;
        margin-right: 1pc;
      }
    }
  }
}
