.cmp-image {
}
.cmp-image__link {
}
.cmp-image__image {
  margin-bottom: 60px !important;
  display: block;
}
.cmp-image__title {
}
